import {createHooks} from "@wordpress/hooks";

/**
 * Global variable for booking. This uses to expose public methods.
 *
 * @use window.wc_bookings.hooks
 *
 * @since 1.15.79
 */
export const HookApi = () => {
	return window.wc_bookings.hooks;
};
