/* globals: booking_form_params */
import $ from 'jquery';

/**
 * Returns the hour offset between the client and the server.
 *
 * @param {*} referenceDate at which to compute offset.
 * @return {number} Number of hours between server and client.
 */
export function get_client_server_timezone_offset_hrs( referenceDate ) {
	if ( ! booking_form_params.timezone_conversion ) {
		return 0;
	}

	let reference_time = moment( referenceDate );
	const client_offset = reference_time.utcOffset();
	reference_time.tz( booking_form_params.server_timezone );
	const server_offset = reference_time.utcOffset();

	return ( client_offset - server_offset ) / 60.0;
}

export function display_error( errorMessage = booking_form_params.i18n_request_failed, after = '#wc-bookings-booking-form' ) {
	// Remove 'active' class from old notices.
	$( '.woocommerce-error.wc-bookings-notice' ).removeClass( 'active' );

	// Create a new error notice element.
	let errorMessageHTML = document.createElement( 'p' );
	errorMessageHTML.setAttribute( 'class', 'woocommerce-error wc-bookings-notice active' );
	errorMessageHTML.setAttribute( 'style', 'display: none;' );
	errorMessageHTML.textContent = errorMessage;
	$( errorMessageHTML ).insertAfter( after );

	// Show a new notice and hide old ones.
	$( '.woocommerce-error.wc-bookings-notice.active' ).slideDown( {
		complete: function() {
			$( '.woocommerce-error.wc-bookings-notice:not(.active)' ).slideUp();
		}
	} );
}
