/**
 * External dependencies
 */
import jQuery from "jquery";

jQuery(document).ready(function($) {
	var startSel = null,
		endSel   = null;

	$( '.wc-bookings-booking-form' ).closest( 'form' ).each( (index, form ) => {
		const $form = $(form);
		if ( $form.find( '.picker' ).data( 'is_range_picker_enabled' ) ) {
			$form.find( 'p.wc_bookings_field_duration' ).hide();
		}
	})

	$('.block-picker').on('click', 'a', function(){
		const bookingform = $(this).closest('form');
		const $picker = bookingform.find('.picker');

		// Enable this event for month duration only to prevent unwanted ajax calls.
		if ( ! bookingform.find( '[name="wc_bookings_field_start_date_yearmonth"]' ).length ) {
			return false;
		}

		var value  = $( this ).data( 'value' );
		var target = $( this ).closest( 'div' ).find( 'input' );
		var range;

		var value        = $(this).data( 'value' );
		var block_picker = $(this).closest( 'ul' );

		set_selected_month( block_picker, value );


		if ( ! $picker.data( 'is_range_picker_enabled' ) ) {
			// If we are not using range picker just trigger change for AJAX request.
			target.val( value ).trigger( 'change' );
			return;
		}

		// Unselect everything and set duration to default value.
		$( this ).parent().siblings().children( 'a' ).removeClass( 'selected' );
		$( '#wc_bookings_field_duration' ).val( 1 );

		if ( startSel && endSel ) {
			// If we have both start and end selection, reset end.
			startSel = $( this ).parent();
			endSel   = null;
		} else if ( startSel ) {
			value = startSel.find( 'a' ).data( 'value' );
			// If we have start selection, we set end selection and do calculation.
			endSel = $( this ).parent();

			if ( startSel.is( endSel ) ) {
				// Not a range, just a single entry.
				range = startSel;
			} else {
				// There is a range so select every entry inclusive.
				range = startSel.nextUntil( endSel ).addBack();
				range = range.add( range.last().next() );
			}

			range.children( 'a' ).addClass( 'selected' );

			// Set duration and date and trigger change for AJAX request.
			$( '#wc_bookings_field_duration' ).val( range.length );
		} else {
			startSel = $( this ).parent();
		}
		target.val( value ).trigger( 'change' );
	});

	var max_width  = 0;
	var max_height = 0;

	$('.block-picker a').each(function() {
		var width  = $(this).width();
		var height = $(this).height();
		if ( width > max_width ) {
			max_width = width;
		}
		if ( height > max_height ) {
			max_height = height;
		}
	});

	$('.block-picker a').width( max_width );
	$('.block-picker a').height( max_height );

	function set_selected_month( block_picker, value ) {
		var submit_button = block_picker.closest( 'form' ).find( '.wc-bookings-booking-form-button' );
		if ( undefined === value ) {
			submit_button.addClass( 'disabled' );
			return;
		}

		var selected_block = block_picker.find( '[data-value="' + value + '"]' );

		if ( undefined === selected_block.data( 'value' ) ) {
			submit_button.addClass( 'disabled' );
			return;
		}

		block_picker.closest( 'ul' ).find( 'a' ).removeClass( 'selected' );
		selected_block.addClass( 'selected' );
		submit_button.removeClass( 'disabled' );
	}
});
